<template>
  <v-app>
    <v-navigation-drawer app fixed  v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp">
     kihid
    </v-navigation-drawer>
    <v-app-bar app dense dark flat clipped-left>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
       <!-- <v-app-bar-title> E-metsavaht</v-app-bar-title>  --> 
       <v-spacer></v-spacer>
       about |  login | register
    </v-app-bar> 
   
      <v-content>
          <l-map ref="map" :zoom="zoom" :center="center"  :crs="crs">
           <l-control-layers />
          <l-tile-layer :url="url" :tms="true" ></l-tile-layer>
          <l-tile-layer :url="urlPhoto" :tms="true" ></l-tile-layer>
         
            
        <l-wms-tile-layer
              v-for="layer in layers"
              :key="layer.name"
              :base-url="baseUrl"
              :format="layer.format"
              :version="layer.version"
              :layers="layer.layers"
              :crs="layer.crs"
              :visible="layer.visible"
              :transparent="layer.transparent"
              :name="layer.name"
              layer-type="base"
          /> 
          </l-map>
   
      </v-content>
     
   
  </v-app>
</template>

<script>

import 'leaflet/dist/leaflet.css';
import { LMap ,LControlLayers ,LTileLayer,LWMSTileLayer} from 'vue2-leaflet';
import L from "leaflet";
import "proj4leaflet";
//import proj4 from "proj4";

//proj4.defs("EPSG:3301" ,"+proj=lcc +lat_1=59.33333333333334 +lat_2=58 +lat_0=57.51755393055556 +lon_0=24 +x_0=500000 +y_0=6375000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"); 


//const est = new L.Proj.CRS(
 // 'EPSG:3301',
 // '+proj=lcc +lat_1=59.33333333333334 +lat_2=58 +lat_0=57.51755393055556 +lon_0=24 +x_0=500000 +y_0=6375000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m /////+no_defs',
 // {
 //   resolutions: [
  //    4000, 3750, 3500, 3250, 3000, 2750, 2500, 2250, 2000, 1750, 1500, 1250, 1000, 750, 650, 500, 250, 100, 50, 20, 10, 5, 2.5, 2, 1.5, 1, 0.5
  //  ],
  //  origin: [2420000, 1350000]
 //})

const est = new L.Proj.CRS('EPSG:3301', '+proj=lcc +lat_1=59.33333333333334 +lat_2=58 +lat_0=57.51755393055556 +lon_0=24 +x_0=500000 +y_0=6375000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs', {
	resolutions: [4000, 2000, 1000, 500, 250, 125, 62.5, 31.25, 15.625, 7.8125, 3.90625, 1.953125, 0.9765625, 0.48828125, 0.244140625, 0.122070313, 0.061035156, 0.030517578, 0.015258789],
	origin: [40500, 5993000],
	bounds: L.bounds([40500, 5993000], [1064500, 7017000])
});
 

export default {
  name: 'App',
  components: { LMap,LControlLayers,LTileLayer,"l-wms-tile-layer": LWMSTileLayer},
  data: () => ({
    drawer: false,
    map:null,
    crs: est,
    zoom: 8,
    center: [58.566791, 24.989689],
    url: 'https://tiles.maaamet.ee/tm/tms/1.0.0/hybriid/{z}/{x}/{y}.png&ASUTUS=joelarula&KESKKOND=api_testimine&IS=TMSNAIDE',
    urlPhoto: 'https://tiles.maaamet.ee/tm/tms/1.0.0/foto/{z}/{x}/{y}.png&ASUTUS=joelarula&KESKKOND=api_testimine&IS=TMSNAIDE',
    baseUrl: 'http://kaart.maaamet.ee/wms/alus',
    layers: [
        {
          name: 'MA-ALUS',
          visible: true,
          crs: est,
          version: '1.1.1',
          format: 'image/png',
          layers: 'MA-ALUS',
          transparent: true,
        }
    ]
  }),
  mounted(){
      this.$nextTick(() => {     
       this.map = this.$refs.map.mapObject
      });
  },
};
</script>
<style>
    #map {
      
    } 
</style>